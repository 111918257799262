window.onload = async () => {

  if (document.getElementsByClassName('swiper').length) {
    import('./slider').then((module) => module.default());
  }

  if (document.getElementsByClassName('popup').length) {
    import('./popup').then((module) => module.default());
  }

  if (document.getElementsByClassName('collapsible-tab').length) {
    import('./tabs').then((module) => module.default());
  }

  if(document.querySelector('.cart')) {
    import('./cartUpdate').then((module) => module.default());
  }

  if(document.querySelector('.woocommerce-message') || document.querySelector('.woocommerce-error')) {
    import('./woocommerceMessage').then((module) => module.default());
  }

  if(document.querySelector('.header-mobile-menu')) {
    import('./navBar').then((module) => module.default());
  }
  
  if(document.querySelector('.faq-block__wrapper')) {
    import('./faqBlock').then((module) => module.default());
  }
  if(document.querySelector('.woocommerce-product-search')) {
    import('./search').then((module) => module.default());
  }
}